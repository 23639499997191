import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Import Icons
import { useRole } from "components/Hooks/UserHooks";
import FeatherIcon from "feather-icons-react";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import { useTranslation } from "react-i18next";
import { ROUTES } from "routes/allRoutes";

const Navdata = () => {
    const history = useNavigate();
    const { t } = useTranslation();
    const { userPermissions } = useRole();

    const [menuActive, setMenuActive] = useState<string>('DASHBOARD');

    // Multi Level
    const [isLevel1, setIsLevel1] = useState<boolean>(false);

    const [isCurrentState, setIsCurrentState] = useState('Dashboard');

    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute("sub-items")) {
            const ul: any = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("sub-items");
                const getID = document.getElementById(id) as HTMLElement
                if (getID)
                    getID.classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
    }, [history]);

    const menuItems: any = [
        // {
        //     id: "dashboard",
        //     label: t('Dashboards'),
        //     icon: <FeatherIcon icon="home" className="icon-dual" />,
        //     link: ROUTES.DASHBOARD,
        //     click: function (e: any) {
        //         setMenuActive((prev) => 'DASHBOARD');
        //         setIsCurrentState((prev) => 'Dashboard');
        //         updateIconSidebar(e);
        //     },
        //     stateVariables: (menuActive === 'DASHBOARD'),
        //     isShow: isHavePermissionRole(ROLES_FOR_APP.DASHBOARD_VIEW, userPermissions),
        //     // subItems: []
        // },
        {
            id: "multilevel12",
            label: t("AI News"),
            icon: <i className="ri-newspaper-line" />,
            link: ROUTES.AI_NEWS,
            click: function (e: any) {
                setMenuActive((prev) => "AI_NEWS");
                setIsCurrentState((prev) => "AI News");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "AI_NEWS",
            isShow: isHavePermissionRole([ROLES_FOR_APP.AI_NEWS], userPermissions),
        },
        {
            id: "multilevel12",
            label: t("Boosting News"),
            icon: <i className="ri-newspaper-line" />,
            link: ROUTES.BOOSTING_NEWS,
            click: function (e: any) {
                setMenuActive((prev) => "BOOSTING_NEWS");
                setIsCurrentState((prev) => "Boosting News");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "BOOSTING_NEWS",
            isShow: isHavePermissionRole([ROLES_FOR_APP.BOOSTING_NEWS], userPermissions),
        },
        // {
        //     id: "multilevel12",
        //     label: t("Real-Time News"),
        //     icon: <i className="ri-newspaper-line" />,
        //     link: ROUTES.REAL_TIME_NEWS,
        //     click: function (e: any) {
        //         setMenuActive((prev) => "REAL_TIME_NEWS");
        //         setIsCurrentState((prev) => "Real-Time News");
        //         updateIconSidebar(e);
        //     },
        //     stateVariables: menuActive === "REAL_TIME_NEWS",
        //     isShow: isHavePermissionRole([ROLES_FOR_APP.REAL_TIME_NEWS], userPermissions),
        // },
        {
            id: "multilevel13",
            label: t("My News"),
            icon: <i className="ri-file-paper-2-line" />,
            link: ROUTES.MY_NEWS,
            click: function (e: any) {
                setMenuActive((prev) => "MY_NEWS");
                setIsCurrentState((prev) => "My News");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "MY_NEWS",
            isShow: isHavePermissionRole([ROLES_FOR_APP.MY_NEWS], userPermissions),
        },
        // {
        //     id: "logout",
        //     label: "Logout",
        //     icon: <FeatherIcon icon="log-out" className="icon-log-out" />,
        //     link: "/logout",
        //     stateVariables: isDashboard,
        //     click: function (e: any) {
        //         e.preventDefault();
        //         setIsDashboard(!isDashboard);
        //         setIsCurrentState( (prev) =>  'Dashboard');
        //         updateIconSidebar(e);
        //     },
        // },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
