import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row, Spinner } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//redux
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
// Formik validation
import { useFormik } from "formik";
import * as Yup from "yup";

// actions
import { login2FA, resetLoginFlag } from "../../store/thunks";

import authApi from 'api/authApi';
import { useTranslation } from 'react-i18next';
import { createSelector } from 'reselect';
import { ROUTES } from 'routes/allRoutes';
import { apiError, loginSuccess } from 'store/auth/login/reducer';
import { StringParam, useQueryParam } from 'use-query-params';
import logoDark from "../../assets/images/logo-dark.png";
//import images
const md5 = require("md5");


const getDeviceId = () => {
    const userAgent = navigator.userAgent;
    const encoder = new TextEncoder();
    const data = encoder.encode(userAgent);
    const platform = window.navigator?.platform;
    const randomString = Math.random().toString(20).substring(2, 14) + Math.random().toString(20).substring(2, 14);
    const deviceID = md5(`${userAgent}-${platform}-${randomString}`);
    return deviceID;
}
const Login = (props: any) => {
    const { t, i18n } = useTranslation();
    const codeInputRef: any = useRef(null);
    const [redirectFrom] = useQueryParam("redirectFrom", StringParam);
    const dispatch = useDispatch<any>();
    const selectLayoutState = (state: any) => state;
    const loginpageData = createSelector(
        selectLayoutState,
        (state) => ({
            user: state.Account.user,
            error: state.Login.error,
            errorMsg: state.Login.errorMsg,
        })
    );
    // Inside your component
    const {
        user, error, errorMsg
    } = useSelector(loginpageData);
    const [userLogin, setUserLogin] = useState<any>([]);
    const [verifyLogin, setVerifyLogin] = useState<any>([]);
    const [passwordShow, setPasswordShow] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [deviceId, setDeviceId] = useState<string>('');

    const [isTwoFactorEnable, setIsTwoFactorEnable] = useState<boolean>(false);

    const params = new URLSearchParams(window.location.search);

    const validation: any = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: userLogin.email || '',
            password: userLogin.password || '',
            device_id: deviceId || ''
        },
        validationSchema: Yup.object({
            email: Yup.string().required(`${t('Please Enter Your Email')}`),
            password: Yup.string().required(`${t('Please Enter Your Password')}`),
            device_id: Yup.string()
        }),
        onSubmit: async (values) => {
            try {
                setLoader((_prev) => true);
                let response = await authApi.login({
                    email: values.email,
                    password: values.password,
                    device_id: values.device_id,
                    provider_type: 'default'
                });
                setLoader((_prev) => false);
                if (response) {
                    let finallogin: any = response?.data;
                    if (!!finallogin?.is_two_factor_enable) {
                        setIsTwoFactorEnable((_prev) => true);
                    } else {
                        const referralUrl = redirectFrom ? redirectFrom : (localStorage.getItem('referralUrl') || ROUTES.AI_NEWS);
                        localStorage.removeItem('referralUrl');
                        localStorage.setItem("authUser", JSON.stringify(finallogin));
                        dispatch(loginSuccess(finallogin));
                        props.router.navigate(referralUrl);
                    }
                }
            } catch (error) {
                setLoader((_prev) => false);
                dispatch(apiError(error));
            }
        }
    });

    const verify: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            code: verifyLogin.code || '',
        },
        validationSchema: Yup.object({
            code: Yup.string().required(`${t('Please Enter Your Code')}`),
        }),
        onSubmit: (values) => {
            dispatch(login2FA({
                code: values?.code,
                email: validation.values.email || '',
                device_id: deviceId || ''
            }, props.router.navigate, ROUTES.HOME));
            setLoader(true)
        }
    });

    useEffect(() => {
        let authUser: any = localStorage.getItem("authUser");
        if (authUser) {
            props?.router?.navigate('dashboard');
        }
    }, [redirectFrom]);

    useEffect(() => {
        if (errorMsg) {
            setTimeout(() => {
                dispatch(resetLoginFlag());
                setLoader(false)
            }, 1000);
        }
    }, [dispatch, errorMsg]);

    useEffect(() => {
        setDeviceId(getDeviceId());
    }, [])

    document.title = `PressAi - ${t('Sign In')}`;

    useEffect(() => {
        if (isTwoFactorEnable && codeInputRef?.current) {
            codeInputRef.current.focus();
        }
    }, [isTwoFactorEnable, codeInputRef?.current]);

    useEffect(() => {
        if (error && isTwoFactorEnable) {
            verify.setFieldValue("code", '');
        }
    }, [error, isTwoFactorEnable]);

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoDark} alt="" height="18" />
                                        </Link>
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium">{t('PRESS Ai Dashboard')}</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4" style={{ boxShadow: "0px 0px 20px 0px rgba(76, 87, 125, 0.02)" }}>
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">{t('Welcome Back !')}</h5>
                                            <p className="text-muted">{t('Sign in to continue to PRESS Ai')}</p>
                                        </div>
                                        <div className="p-2 mt-4">
                                            {!isTwoFactorEnable ? (<>
                                                {error && error ? (<Alert color="danger "> {error} </Alert>) : null}
                                                <Form
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        validation.handleSubmit();
                                                        return false;
                                                    }}
                                                    action="#">

                                                    <div className="mb-3">
                                                        <Label htmlFor="email" className="form-label">{t('Email')}</Label>
                                                        <Input
                                                            name="email"
                                                            className="form-control"
                                                            placeholder={t('Enter email')}
                                                            type="email"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.email || ""}
                                                            invalid={
                                                                validation.touched.email && validation.errors.email ? true : false
                                                            }
                                                        />
                                                        {validation.touched.email && validation.errors.email ? (
                                                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                    <div className="mb-3">
                                                        {/* <div className="float-end">
                                                        <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                                                    </div> */}
                                                        <Label className="form-label" htmlFor="password-input">{t('Password')}</Label>
                                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                                            <Input
                                                                name="password"
                                                                value={validation.values.password || ""}
                                                                type={passwordShow ? "text" : "password"}
                                                                className="form-control pe-5"
                                                                placeholder={t('Enter Password')}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                invalid={
                                                                    validation.touched.password && validation.errors.password ? true : false
                                                                }
                                                            />
                                                            {validation.touched.password && validation.errors.password ? (
                                                                <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                            ) : null}
                                                            <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" onClick={() => setPasswordShow(!passwordShow)} type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                                        </div>
                                                    </div>
                                                    <div className="mt-4">
                                                        <Button color="success"
                                                            disabled={!!loader}
                                                            className="btn btn-success w-100" type="submit">
                                                            {!!loader && <Spinner size="sm" className='me-2'> {t('Loading')}... </Spinner>}
                                                            {t('Sign In')}
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </>) :
                                                (
                                                    <>
                                                        {error && error ? (<Alert color="danger "> {error} </Alert>) : null}
                                                        <Form
                                                            onSubmit={(e) => {
                                                                e.preventDefault();
                                                                verify.handleSubmit();
                                                                return false;
                                                            }}
                                                            action="#">
                                                            <div className="mb-3">
                                                                <Label htmlFor="code" className="form-label">{t('Verification Code')}</Label>
                                                                <Input
                                                                    name="code"
                                                                    className="form-control"
                                                                    placeholder={t('Enter Code')}
                                                                    type="text"
                                                                    onChange={verify.handleChange}
                                                                    onBlur={verify.handleBlur}
                                                                    value={verify.values.code || ""}
                                                                    invalid={
                                                                        verify.touched.code && verify.errors.code ? true : false
                                                                    }
                                                                    autoFocus
                                                                    innerRef={codeInputRef}
                                                                />
                                                                {/* {verify.touched.code && verify.errors.code ? (
                                                                    <FormFeedback type="invalid">{verify.errors.code}</FormFeedback>
                                                                ) : null} */}
                                                            </div>
                                                            <div className="mt-4">
                                                                <Button color="success"
                                                                    disabled={!!loader}
                                                                    className="btn btn-success w-100" type="submit">
                                                                    {!!loader && <Spinner size="sm" className='me-2'> {t('Loading')}... </Spinner>}
                                                                    {t('Button Verify')}
                                                                </Button>
                                                            </div>
                                                            <div className="mt-4 text-center"><p className="mb-0"><a className="text-primary text-decoration-underline" href="/login" onClick={(e: any) => {
                                                                e.preventDefault();
                                                                verify.setFieldValue("code", '');
                                                                setIsTwoFactorEnable((_prev) => false);
                                                            }}> {t('Back to Login')} </a> </p></div>
                                                        </Form>
                                                    </>)}
                                        </div>
                                    </CardBody>
                                </Card>
                                {/* 
                                <div className="mt-4 text-center">
                                    <p className="mb-0">Don't have an account ? <Link to="/register" className="fw-semibold text-primary text-decoration-underline"> Signup </Link> </p>
                                </div> */}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(Login);