import React from 'react';
import parse, { domToReact } from 'html-react-parser';

interface HighlightTextProps {
    text: string;
    keyword: string;
    sub_keyword?: string;
    opacity_bg?: number
}

const HighlightText: React.FC<HighlightTextProps> = ({ text, keyword, sub_keyword, opacity_bg = 1 }) => {
    if (!keyword && !sub_keyword) return <span>{text}</span>;

    const regex = new RegExp(`(${keyword})|(${sub_keyword})`, 'gi');

    const highlightContent = (content: string) => {
        const parts = content.split(regex);
        return parts.map((part, index) => {
            if (part?.toLowerCase() === keyword?.toLowerCase()) {
                return (
                    <span key={index} style={{ backgroundColor: `rgba(255, 255, 0, ${opacity_bg})` }}>
                        {part}
                    </span>
                );
            } else if (sub_keyword && part?.toLowerCase() === sub_keyword?.toLowerCase()) {
                return (
                    <span key={index} style={{ backgroundColor: `rgba(168, 210, 251, ${opacity_bg})` }}>
                        {part}
                    </span>
                );
            }
            return part;
        });
    };

    const parsedText = parse(text, {
        replace: (domNode: any) => {
            if (domNode.type === 'text') {
                return <>{highlightContent(domNode.data)}</>;
            }
        },
    });

    return <span>{parsedText}</span>;
};

export default HighlightText;
