import { Option } from "api/types/_public";

export const SCHEDULE_OPTIONS: Option[] = [
  {
    label: "Now",
    value: "now",
  },
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
];

export const SCHEDULE_MONTHLY_OPTIONS: Option[] = Array.from(
  { length: 31 },
  (_, index) => {
    const day = (index + 1).toString().padStart(2, "0");
    return { label: `${day}th`, value: day };
  }
);

export const ARR_INDEX_TEXT_DAY_OF_WEEK: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const SCHEDULE_WEEKLY_OPTIONS: Option[] = [
  {
    label: "Monday",
    value: "1",
  },
  {
    label: "Tuesday",
    value: "2",
  },
  {
    label: "Wednesday",
    value: "3",
  },
  {
    label: "Thursday",
    value: "4",
  },
  {
    label: "Friday",
    value: "5",
  },
  {
    label: "Saturday",
    value: "6",
  },
  {
    label: "Sunday",
    value: "7",
  },
];

export const TYPE_SHOW_MORE_HASHTAG = {
  SCROLL: 'scroll',
  MODAL: 'modal'
}

export enum TYPE_BANNER_ADVERTISING {
  SINGLE_BANNER = 1,
  SLIDE_BANNER = 2,
};

export const COLORS_SECTION_KEYWORD: any = {
  '100': 'success',
  '200': 'danger',
  '201': 'primary',
  '202': 'warning',
  '203': 'green',
  '204': 'secondary',
  '205': 'purple',
  '206': 'pink',
  '207': 'info',
  '300': 'dark'
};

export enum SECTION_KEYWORD {
  GOOGLE_TREND = 100,
  NAVER_POLITICS = 200,
  NAVER_ECONOMY = 201,
  NAVER_SOCIETY = 202,
  NAVER_LIFE_CULTURE = 203,
  NAVER_IT_SCIENCE = 204,
  NAVER_WORLD = 205,
  NAVER_ENTERTAIN = 206,
  NAVER_SPORT = 207,
  BOOSTING = 300,
  REAL_TIME = 400,
}

export const SECTION_KEYWORD_OPTIONS: Option[] = [
  {
    label: `Google`,
    value: String(SECTION_KEYWORD.GOOGLE_TREND),
  },
  {
    label: `Politics`,
    value: String(SECTION_KEYWORD.NAVER_POLITICS),
  },
  {
    label: `Economy`,
    value: String(SECTION_KEYWORD.NAVER_ECONOMY),
  },
  {
    label: `Society`,
    value: String(SECTION_KEYWORD.NAVER_SOCIETY),
  },
  {
    label: `Life/Culture`,
    value: String(SECTION_KEYWORD.NAVER_LIFE_CULTURE),
  },
  {
    label: `IT/Science`,
    value: String(SECTION_KEYWORD.NAVER_IT_SCIENCE),
  },
  {
    label: `World`,
    value: String(SECTION_KEYWORD.NAVER_WORLD),
  },
  {
    label: `Entertainment`,
    value: String(SECTION_KEYWORD.NAVER_ENTERTAIN),
  },
  {
    label: `Sports`,
    value: String(SECTION_KEYWORD.NAVER_SPORT),
  },
  // {
  //   label: `Boosting`,
  //   value: String(SECTION_KEYWORD.BOOSTING)
  // }
];

export enum ENUM_STATUS_NEWS {
  USED = 1,
  UNUSED = 0,
};

export const COLORS_STATUS_NEWS: any = {
  '0': 'success',
  '1': 'danger',
};

export const STATUS_NEWS_OPTIONS: Option[] = [
  {
    label: 'Unused', // Unused
    value: `${ENUM_STATUS_NEWS.UNUSED}`,
  },
  {
    label: 'Used', // Used
    value: `${ENUM_STATUS_NEWS.USED}`,
  },
];


export enum ENUM_SENTIMENT_NEWS {
  POSITIVE = 'Positive',
  NEGATIVE = 'Negative',
  NEUTRAL = 'Neutral',
};

export const COLORS_SENTIMENT_NEWS: any = {
  [`${ENUM_SENTIMENT_NEWS.POSITIVE}`]: 'primary',
  [`${ENUM_SENTIMENT_NEWS.NEGATIVE}`]: 'danger',
  [`${ENUM_SENTIMENT_NEWS.NEUTRAL}`]: 'secondary',
};

export const SENTIMENT_NEWS_OPTIONS: Option[] = [
  {
    label: 'Positive', // Positive
    value: `${ENUM_SENTIMENT_NEWS.POSITIVE}`,
  },
  {
    label: 'Negative', // Negative
    value: `${ENUM_SENTIMENT_NEWS.NEGATIVE}`,
  },
  {
    label: 'Neutral', // Neutral
    value: `${ENUM_SENTIMENT_NEWS.NEUTRAL}`,
  },
];

export const IS_OPTIONS: Option[] = [
  {
    label: "YES",
    value: "1",
  },
  {
    label: "NO",
    value: "0",
  },
];

export const METHOD_OPTIONS: Option[] = [
  {
    label: "GET",
    value: "GET",
  },
  {
    label: "POST",
    value: "POST",
  },
  {
    label: "PUT",
    value: "PUT",
  },
  {
    label: "DELETE",
    value: "DELETE",
  },
];

export const COLOR_TYPE_SENTIMENT: any = {
  'Positive': 'primary',
  'Negative': 'danger',
  'Neutral': 'muted'
}

export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
