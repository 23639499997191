const ROLES_FOR_APP = {
    DASHBOARD_VIEW: "view",

    ROLE_LIST: "role_list",
    ROLE_CREATE: "role_create",
    ROLE_UPDATE: "role_update",
    ROLE_DELETE: "role_remove",

    USER_LIST: "user_list",
    USER_CREATE: "user_create",
    USER_UPDATE: "user_update",
    USER_DELETE: "user_remove",

    AI_NEWS: "ai_news",
    BOOSTING_NEWS: "boosting_news",
    REAL_TIME_NEWS: "real_time_news",
    MY_NEWS: "my_news"

};

const isHavePermissionRole = (keyRoles: string | string[], permissions: { id: string | number, name: string }[] = []): boolean => {
    const roles = typeof keyRoles === 'string' ? [keyRoles] : keyRoles;
    return true; // permissions?.some(item => roles?.includes(item.name));
};

export { ROLES_FOR_APP, isHavePermissionRole };